<template>
    <div class="bg-gradient-to-br from-primary-300 to-primary-700 min-h-screen">
        <div class="flex min-h-full flex-col justify-center py-12 px-6 lg:px-8">
            <div class="sm:mx-auto sm:w-full sm:max-w-md">
                <div class="mx-auto h-32 w-auto">
                    <slot name="logo"/>
                </div>
                <h2 class="mt-6 text-center text-3xl font-bold tracking-tight text-white">{{ $t('Sign in') }}</h2>
            </div>
            <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                <div class="bg-white rounded-md py-8 px-4 shadow sm:rounded-lg sm:px-10">
                    <slot/>
                </div>
            </div>
        </div>
    </div>
</template>

